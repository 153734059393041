




import { Navigation } from "@/mixins/navigation";
import Component, { mixins } from "vue-class-component";

@Component({})
export default class StaffApp extends mixins(Navigation) {}
